import themes from '@/data/themes'
import { CLIENT } from '@/utils/getEnv'
import { CircularProgress } from '@material-ui/core'
import { ReportProblemOutlined, ThumbUpOutlined } from '@material-ui/icons'
import { NextPage } from 'next'
import { FC, ReactNode } from 'react'
import styled from 'styled-components'
import FondasiLogoSVG from '../../assets/svg/fondasi-logo-black.svg'
import BannerLogin from './BannerLogin'

type Props = any & {
  children?: ReactNode
}

const AuthLayout: FC<Props> = ({ children }) => {

  return (
    <>
      <Container>
        <BannerLogin />
        <div className='container-form'>
          {children}
        </div>
      </Container >
    </>
  )
}

export default AuthLayout

export const withAuthLayout = (Component: NextPage): NextPage => {
  const Layout: FC<any> = (props) => {
    return (
      <>
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      </>
    )
  }

  return Layout
}


const Container = styled.div`
  height: 100vh;
  /* display: flex; */
  width: 100%;
  /* flex-direction: row;
  text-align: center;
  justify-content: space-around;
  align-items: center; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  .container-form {
    padding: 24px;
    padding-right: ${CLIENT === 'smartfren' ? '70px' : '24px'};
    margin: auto;
    overflow-y: auto;
    max-height: 100vh;
    box-sizing: border-box;
  }

  @media  (max-width: 820px) {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    .container-form {
      padding: 24px;
      height: 100%;
      max-height: unset;
      overflow: hidden;
    }
  }
  @media  (max-width: 1000px) {
    overflow: auto;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Trueno', sans-serif;
  }

`


interface AuthLayoutCenterProps  {
  children?: ReactNode,
  box?: boolean,
  loading?: boolean,
  isSucceed?: boolean,
  isError?: boolean
}

export const AuthLayoutCenter: FC<AuthLayoutCenterProps> = ({ children, box, loading, isSucceed, isError }) => {
  const loginTheme = themes[CLIENT]?.login
  const logoUrl = loginTheme?.logoUrl

  return (
    <>
      <ContainerAuthLayoutCenter>
        {
          logoUrl ?
            <ClientLogoAuthCenter src={logoUrl} /> :
            <FondasiLogoSVG height="120" width="200" />
        }
        {
          loading ?
            <CircularProgress color='primary' /> :
            box ?
              <ContentAuthLayoutCenter>
                {children}
              </ContentAuthLayoutCenter> :
              isSucceed ?
                <>
                  <ThumbUpOutlined style={{ color: '#00B256', fontSize: 50 }} />
                  <br />
                  {children}
                </> :
                isError ?
                  <>
                    <ReportProblemOutlined style={{ color: '#CE0000', fontSize: 60 }} />
                    <br />
                    {children}
                  </> :
                  children
        }

      </ContainerAuthLayoutCenter>
    </>
  )
}

export const StyledFormAuth = styled.form`
  /* padding: 16px; */
  max-width: 500px;
`

export const withAuthLayoutCenter = (Component: NextPage): NextPage => {
  const Layout: FC<any> = (props) => {
    return (
      <>
        <AuthLayoutCenter>
          <Component {...props} />
        </AuthLayoutCenter>
      </>
    )
  }

  return Layout
}


const ContainerAuthLayoutCenter = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
  align-items: center;
  width: 100vw;
`

const ContentAuthLayoutCenter = styled.div`
  max-width: 500px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  padding: 24px;

  h1, h2, h3, h4, h5, h6 {
    margin: 0px;
    font-family: 'Trueno', sans-serif;
  }
`
const ClientLogoAuthCenter = styled.img`
  width: 100%;
  margin: 50px 0;
  max-width: 15vw;
  /* flex: 1; */
`
export const StyledSubtitle = styled.p`
  font-family: 'Trueno', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
  color: #000000;

  white-space: pre-line;
`
export const StyledMessageConfirm = styled.div`
  font-family: 'Trueno', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  /* or 125% */

  text-align: center;

  color: #000000;
`
export const StyledSubMessageConfirm = styled.div`
  font-family: 'Trueno', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #7B7B7B;

  margin-top: 30px;
  margin-bottom: 16px;
  white-space: pre-line;
`